import React from 'react'
import banner from "../assets/img/BannerWebTeleventas1.png";
import bannerMob from "../assets/img/BannerWebTeleventasMob.png";

const Home = () => {

  return (
    <div>
      {window.innerWidth <= 1001? (
        <img
            src={bannerMob}
            alt="Promocion Mobile"
            style={{ width: "100%", height: "100%" }}
        />
      ):(
        <img
            src={banner}
            alt="Promocion"
            style={{width: "100%", height: "100%" }}
        />
      )}
    </div>
  )
}

export default Home