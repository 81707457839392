import "./assets/css/App.css";
import { Logo } from "./components/Logo";
import Home from "./components/Home.js";



const App = () => {

  return (
    <>
      <div className="fullPage">
        <div className="top-bar">
          <Logo />
        </div>
        <div className='home-position'>
          <Home/>
        </div>
      </div>       
    </>
  )
}

export default App