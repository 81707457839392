import React from "react";
import logo from '../assets/img/Logo.png'
import '../assets/css/logo.css'

export const Logo = () => {
  return (
      <div className="logo">
          <img className="logo-position" src={logo} alt="logo" />
      </div>
  );
};

